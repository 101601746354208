import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Button, {
  ButtonShape,
  ButtonSize,
  ButtonVariants,
} from '@/components/switchback/Button/Button';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import Modal from '@/components/switchback/Modal';
import Popup from '@/components/switchback/Popup/Popup';
import { ESocialShares, SOCIAL_SHARES } from '@/constants/socialShares';
import {
  SHARE_URL_FACEBOOK,
  SHARE_URL_MAIL,
  SHARE_URL_PINTEREST,
  SHARE_URL_TWITTER,
} from '@/constants/urls';
import { trackListingSharedEvent } from '@/services/analytics/listings';
import { trackEvent } from '@/services/track-event';
import { EShareMethodType } from '@/services/types/referral/referral';
import { IData } from '@/services/types/search/rentals/id';
import { buildAndOpenShareWindow } from '@/utility/socialShare';

interface IShareModalProps {
  description: string;
  onClose?: () => void;
  isOpen: boolean;
  socialImage?: string;
  rentalId?: number;
  listingData?: IData;
  title: string;
  ['data-testid']?: string;
  isStay?: boolean;
  isOutdoorsyStay?: boolean;
}

const ShareModal: React.FC<IShareModalProps> = ({
  isOpen,
  onClose,
  title,
  listingData,
  rentalId,
  socialImage,
  isStay,
  isOutdoorsyStay = false,
}) => {
  const intl = useIntl();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const [relativeTo, setRelativeTo] = useState<Element | null>(null);
  const [popupCache, setPopupCache] = useState<number>(0);

  const handleModalClose = () => {
    onClose && onClose();
  };

  const handlePopupClose = () => {
    setPopupCache(popupCache + 1);
    setRelativeTo(null);
  };

  const handleShare = (
    shareAction: string,
    windowWidth?: number,
    windowHeight?: number,
    e?: React.MouseEvent,
  ) => {
    switch (shareAction) {
      case ESocialShares.TWITTER:
        buildAndOpenShareWindow({
          urlTemplate: SHARE_URL_TWITTER,
          url: encodeURIComponent(window.location.href),
          title: encodeURIComponent(title),
          windowTitle: ESocialShares.TWITTER,
          w: windowWidth,
          h: windowHeight,
        });
        if (listingData) {
          trackListingSharedEvent(listingData, EShareMethodType.TWITTER, false, isOutdoorsyStay);
        }
        break;
      case ESocialShares.FACEBOOK:
        buildAndOpenShareWindow({
          urlTemplate: SHARE_URL_FACEBOOK,
          url: encodeURIComponent(window.location.href),
          windowTitle: ESocialShares.FACEBOOK,
          w: windowWidth,
          h: windowHeight,
        });
        if (listingData) {
          trackListingSharedEvent(listingData, EShareMethodType.FACEBOOK, false, isOutdoorsyStay);
        }
        break;
      case ESocialShares.PINTEREST:
        buildAndOpenShareWindow({
          urlTemplate: SHARE_URL_PINTEREST,
          url: encodeURIComponent(window.location.href),
          title: encodeURIComponent(title),
          image: socialImage,
          windowTitle: ESocialShares.PINTEREST,
          w: windowWidth,
          h: windowHeight,
        });
        if (listingData) {
          trackListingSharedEvent(listingData, EShareMethodType.PINTEREST, false, isOutdoorsyStay);
        }
        break;
      case ESocialShares.MAIL:
        buildAndOpenShareWindow({
          urlTemplate: SHARE_URL_MAIL,
          url: encodeURIComponent(window.location.href),
          title: intl.formatMessage(
            {
              defaultMessage: 'A friend wants you to check out this {rentalType} on Outdoorsy',
              id: 't7X/H5',
            },
            { rentalType: isStay ? 'stay' : 'RV' },
          ),
          description: encodeURIComponent(
            `${title}
            \n${window.location.href}
            \n${intl.formatMessage({
              defaultMessage:
                'Outdoorsy is the place for people looking to book the perfect RV vacation to rent RVs directly from private owners.',
              id: '6MTF6V',
            })}`,
          ),
          windowTitle: ESocialShares.MAIL,
          closeAfterOpen: true,
        });
        if (listingData) {
          trackListingSharedEvent(listingData, EShareMethodType.EMAIL, false, isOutdoorsyStay);
        }
        break;
      case ESocialShares.LINK:
        if (e?.nativeEvent.target instanceof Element) {
          if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
          }

          setRelativeTo(e.nativeEvent.target);

          timeoutRef.current = setTimeout(() => {
            handlePopupClose();
          }, 2000);
        }
        navigator.clipboard.writeText(window.location.href);
        if (listingData) {
          trackListingSharedEvent(listingData, EShareMethodType.COPY_LINK, false, isOutdoorsyStay);
        }
        break;
    }

    if (rentalId) {
      trackEvent({
        event: 'Refer a friend - Share',
        actionType: shareAction, // actionType - The social network that they shared it on
        rentalId: rentalId,
      });
    }
  };

  return (
    <Modal
      title={intl.formatMessage({
        defaultMessage: 'Share the adventure',
        id: 'oHt1Gp',
      })}
      titleClass="text-center pl-2"
      subTitle={intl.formatMessage({
        defaultMessage: 'Maybe it ends up inspiring a trip to somewhere amazing.',
        id: 'LubTTF',
      })}
      subTitleClass="text-center pl-2 mx-6"
      open={isOpen}
      onClose={handleModalClose}
      data-testid="listing-share-modal">
      <div
        key={popupCache}
        className="flex flex-wrap justify-center mx-8 mt-8 mb-8 lg:mt-9 lg:mb-13">
        {Object.entries(SOCIAL_SHARES).map(([key, item]) => {
          return (
            <Button
              key={key}
              variant={ButtonVariants.darkGrayOutlined}
              shape={ButtonShape.square}
              size={ButtonSize.medium}
              onClick={(e: React.MouseEvent) =>
                handleShare(item.shareAction, item.shareWindowWidth, item.shareWindowHeight, e)
              }
              className="mx-2 mb-4">
              <Icon
                className={item.color && `text-social-${item.color}`}
                name={item.icon}
                size={IconSize.normal}
              />
            </Button>
          );
        })}

        <Popup show={Boolean(relativeTo)} onDismiss={handlePopupClose} relativeTo={relativeTo}>
          {intl.formatMessage({
            defaultMessage: 'Link copied',
            id: 'zU/q1N',
          })}
        </Popup>
      </div>
    </Modal>
  );
};

export default ShareModal;
