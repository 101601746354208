import { TIconName } from '@outdoorsyco/bonfire';

export enum ELocationDetailCategory {
  SCENERY = 'Scenery',
  ACTIVITIES = 'Activities',
}

export enum ELocationDetails {
  BALL_FIELD = 'ball_field',
  BEACH = 'beach',
  BIKING = 'biking',
  BILLIARDS = 'billiards',
  BOATING = 'boating',
  CAMPING = 'camping',
  CLIMBING = 'climbing',
  COUNTRYSIDE = 'countryside',
  COURTS = 'courts',
  DESERT = 'desert',
  FARM = 'farm',
  FISHING = 'fishing',
  HIKING = 'hiking',
  HORSEBACK_RIDING = 'horseback_riding',
  HOT_SPRING = 'hot_spring',
  HOTSPRING = 'hotspring',
  KAYAKING = 'kayaking',
  LAKE = 'lake',
  LAWN_GAMES = 'lawn_games',
  MOUNTAINS = 'mountains',
  OCEAN = 'ocean',
  OFF_ROADING = 'off_roading',
  PARK = 'park',
  RANCH = 'ranch',
  ROCK_CLIMBING = 'rock_climbing',
  SKI = 'ski_in_ski_out',
  SKIING_OR_SNOWBOARDING = 'skiing_or_snowboarding',
  SKIING_SNOWBOARDING = 'skiing_snowboarding',
  SKI_OR_SNOWBOARD = 'ski_or_snowboard',
  SLEDDING = 'sledding',
  SURFING = 'surfing',
  SWIMMING_HOLE = 'swimming_hole',
  SWIMMING = 'swimming',
  VINEYARD = 'vineyard',
  WATERFRONT = 'waterfront',
  WOODS = 'woods',
  WATERSPORTS_CAMPGROUNDS = 'watersports',
  WATERSPORTS = 'paddleboarding_watersports',
  WILDLIFE_WATCHING = 'wildlife_watching',
}
export const LOCATION_DETAILS_ICONS: Record<ELocationDetails, TIconName> = {
  [ELocationDetails.BALL_FIELD]: 'Amenities.BallField',
  [ELocationDetails.BEACH]: 'Terrain.Terrain.Beach',
  [ELocationDetails.BIKING]: 'Activities.Activities.Biking',
  [ELocationDetails.BILLIARDS]: 'Amenities.Billards',
  [ELocationDetails.BOATING]: 'Activities.Activities.Boating',
  [ELocationDetails.CAMPING]: 'Activities.Activities.Camping',
  [ELocationDetails.CLIMBING]: 'Activities.Activities.Climbing',
  [ELocationDetails.COUNTRYSIDE]: 'Terrain.Terrain.Countryside',
  [ELocationDetails.COURTS]: 'Terrain.Terrain.Countryside',
  [ELocationDetails.DESERT]: 'Terrain.Terrain.Desert',
  [ELocationDetails.FARM]: 'Terrain.Terrain.Farm',
  [ELocationDetails.FISHING]: 'Activities.Activities.Fishing',
  [ELocationDetails.HIKING]: 'Activities.Activities.Hiking',
  [ELocationDetails.HORSEBACK_RIDING]: 'Activities.Activities.HorsebackRiding',
  [ELocationDetails.HOT_SPRING]: 'Terrain.Terrain.HotSpring',
  [ELocationDetails.HOTSPRING]: 'Terrain.Terrain.HotSpring',
  [ELocationDetails.KAYAKING]: 'Activities.Activities.Kayaking',
  [ELocationDetails.LAKE]: 'Terrain.Terrain.Lake',
  [ELocationDetails.LAWN_GAMES]: 'Amenities.LawnGames',
  [ELocationDetails.MOUNTAINS]: 'Terrain.Terrain.Mountains',
  [ELocationDetails.OCEAN]: 'Terrain.Terrain.Ocean',
  [ELocationDetails.OFF_ROADING]: 'Activities.Activities.OffRoading',
  [ELocationDetails.PARK]: 'Terrain.Terrain.Park',
  [ELocationDetails.RANCH]: 'Terrain.Terrain.Ranch',
  [ELocationDetails.ROCK_CLIMBING]: 'Activities.Activities.Climbing',
  [ELocationDetails.SKI]: 'Terrain.Terrain.Ski',
  [ELocationDetails.SLEDDING]: 'Activities.Activities.Sledding',
  [ELocationDetails.SURFING]: 'Activities.Activities.Surfing',
  [ELocationDetails.SWIMMING_HOLE]: 'Terrain.Terrain.SwimmingHole',
  [ELocationDetails.SWIMMING]: 'Activities.Activities.Swimming',
  [ELocationDetails.SKIING_OR_SNOWBOARDING]: 'Activities.Activities.Skiing',
  [ELocationDetails.SKIING_SNOWBOARDING]: 'Activities.Activities.Skiing',
  [ELocationDetails.SKI_OR_SNOWBOARD]: 'Activities.Activities.Skiing',
  [ELocationDetails.VINEYARD]: 'Terrain.Terrain.Vineyard',
  [ELocationDetails.WATERFRONT]: 'Terrain.Terrain.Waterfront',
  [ELocationDetails.WATERSPORTS]: 'Activities.Activities.Watersports',
  [ELocationDetails.WATERSPORTS_CAMPGROUNDS]: 'Activities.Activities.Watersports',
  [ELocationDetails.WILDLIFE_WATCHING]: 'Activities.Activities.WildlifeWatching',
  [ELocationDetails.WOODS]: 'Terrain.Terrain.Woods',
};
