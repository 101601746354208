/**
 * Opens up a new window in the middle of the screen.
 * Copied from https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
 *
 * @argument {string} url - The url of the new window.
 * @argument {string} name - The name of the window. Will also be used as the title of the window until the page loads.
 * @argument {number} w - The width of the new window.
 * @argument {number} h - The height of the new window.
 */
export const openWindow = function (
  url: string,
  name: string,
  w: number,
  h: number,
  closeAfterOpen: boolean,
) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const windowWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const windowHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const systemZoom = windowWidth / window.screen.availWidth;
  const left = (windowWidth - w) / 2 / systemZoom + dualScreenLeft;
  const top = (windowHeight - h) / 2 / systemZoom + dualScreenTop;

  const newWindow = window.open(
    url,
    name,
    `scrollbars=yes, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`,
  );

  if (newWindow) {
    newWindow.focus();
  }

  if (newWindow && closeAfterOpen) {
    setTimeout(() => {
      newWindow.close();
    }, 3500);
  }
};
