import { openWindow } from '@/utility/openWindow';

export interface IBuildAndShareProps {
  urlTemplate: string;
  url: string;
  title?: string;
  description?: string;
  image?: string;
  windowTitle: string;
  w?: number;
  h?: number;
  closeAfterOpen?: boolean;
}

export const buildAndOpenShareWindow = ({
  urlTemplate,
  url,
  title = '',
  description = '',
  image = '',
  windowTitle,
  w = 640,
  h = 480,
  closeAfterOpen = false,
}: IBuildAndShareProps) => {
  const shareUrl = urlTemplate.replace(/\{\{([^}]+)\}\}/g, (fullMatch: string): string => {
    // Remove the wrapping curly braces
    const match = fullMatch.slice(2, -2);

    switch (match) {
      case 'url':
        return url;
      case 'title':
        return title;
      case 'image':
        return image;
      case 'description':
        return description;

      default:
        return `{{${match}}`; // Put the wrapping braces back
    }
  });

  openWindow(shareUrl, windowTitle, w, h, closeAfterOpen);
};
