import { Address } from '@/graphql/types-and-hooks';

export type RefereeType = 'owner' | 'renter';
enum Referee {
  HOST = 'host',
  GUEST = 'guest',
}
export const Referees: Record<RefereeType, Referee> = {
  owner: Referee.HOST,
  renter: Referee.GUEST,
};
interface IReferee {
  avatar_url: string;
  credit_opportunity: number;
  email: string;
  name: string | null;
  referee_type: RefereeType;
}
export interface IReferralStatsProps {
  available_credits: number;
  completed_referees: IReferee[];
  earned_credits: number;
  pending_credits: number;
  pending_referees: IReferee[];
  sent_referees: IReferee[];
}

export interface IRefereeEmail {
  email: string;
}

export enum EShareMethodType {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  COPY_LINK = 'copy_link',
  PINTEREST = 'pinterest',
  SHARE_SHEET = 'share_sheet',
  QR_LIKELY = 'qr_likely',
}

export enum EContactsImportType {
  GMAIL = 'gmail',
  YAHOO = 'yahoo',
  OUTLOOK = 'outlook',
}

export interface ISendReferralsProps {
  referral_type?: 'new_user' | 'new_booking';
  referee_type: RefereeType;
  referees: IRefereeEmail[];
}
interface IProfile {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  disable_phone: string;
  address: Address;
}

//only need the referral_code, profile for now
export interface IFullUserProps {
  referral_code: string;
  profile: IProfile;
  metadata?: {
    apps?: Record<string, Record<string, string>>;
  };
}

interface IReferred {
  email: string;
  first_name: string | null;
  last_name: string | null;
  phone: string | number | null;
}
export interface IReferredProps {
  referred: IReferred[] | [];
}
