import { FACEBOOK, LINK, MAIL, PINTEREST, TWITTER } from '@/components/switchback/Icon/assets';
import { IconType } from '@/components/switchback/Icon/IconComponent';

export enum ESocialShares {
  TWITTER = 'twitter',
  FACEBOOK = 'facebook',
  PINTEREST = 'pinterest',
  MAIL = 'mail',
  LINK = 'link',
}

export const SOCIAL_SHARES: Record<
  ESocialShares,
  {
    icon: IconType;
    color?: string;
    shareAction: string;
    shareWindowWidth?: number;
    shareWindowHeight?: number;
  }
> = {
  [ESocialShares.TWITTER]: {
    icon: TWITTER,
    color: ESocialShares.TWITTER,
    shareAction: ESocialShares.TWITTER,
    shareWindowWidth: 700,
    shareWindowHeight: 320,
  },
  [ESocialShares.FACEBOOK]: {
    icon: FACEBOOK,
    color: ESocialShares.FACEBOOK,
    shareAction: ESocialShares.FACEBOOK,
    shareWindowWidth: 700,
    shareWindowHeight: 500,
  },
  [ESocialShares.PINTEREST]: {
    icon: PINTEREST,
    color: ESocialShares.PINTEREST,
    shareAction: ESocialShares.PINTEREST,
    shareWindowWidth: 750,
    shareWindowHeight: 680,
  },
  [ESocialShares.MAIL]: {
    icon: MAIL,
    shareAction: ESocialShares.MAIL,
  },
  [ESocialShares.LINK]: {
    icon: LINK,
    shareAction: ESocialShares.LINK,
  },
};
