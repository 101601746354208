import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Divider from '@/components/switchback/Divider/Divider';
import Heading from '@/components/switchback/Heading/Heading';
import { ELocationDetails, LOCATION_DETAILS_ICONS } from '@/constants/locationDetails';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { getLocationDetails, ILocationDetailsItem } from '@/redux/selectors/listing/rentalDetails';

import ClampModal from '../ClampModal/ClampModal';
import FeatureItem from '../Features/FeatureItem';

const LocationHighlights: React.FC = () => {
  const locationDetails = useSelector(getLocationDetails);
  const intl = useIntl();
  const { isMobile } = useBreakpoint();

  if (!locationDetails) {
    return null;
  }
  const { scenery: sceneryDetails, activities: activitiesDetails } = locationDetails;

  if (!sceneryDetails.length || !activitiesDetails.length) {
    return null;
  }
  const totalLength = sceneryDetails.length + activitiesDetails.length;
  const length =
    sceneryDetails.length >= activitiesDetails.length
      ? activitiesDetails.length
      : sceneryDetails.length;
  const showMoreText = intl.formatMessage(
    {
      defaultMessage: 'Show all {highlightCount, plural, one {# highlight} other {# highlights}}',
      id: 'i7Lhwa',
    },
    { highlightCount: totalLength },
  );
  const modalTitle = intl.formatMessage({
    defaultMessage: 'Location highlights',
    id: '2ZcIFv',
  });

  return (
    <>
      <Divider className="my-8 bg-grey-200" />
      <div className="mb-4 md:mb-6">
        <Heading level={4} className="font-bold md:h3 text-700 semiHighlight">
          <FormattedMessage defaultMessage="Location highlights" id="2ZcIFv" />
        </Heading>
      </div>
      <ClampModal
        showMoreText={showMoreText}
        isList
        hasMore={totalLength > (isMobile ? 4 : 6)}
        modalContent={
          <ModalContent sceneryDetails={sceneryDetails} activitiesDetails={activitiesDetails} />
        }
        modalTitle={modalTitle}>
        <div className="grid gap-0 md:grid-cols-2 grid-cols-1">
          <div className={'mt-0'}>
            <p className="mb-5 text-lg font-bold leading-500 semiHighlight">
              {intl.formatMessage({ defaultMessage: 'Scenery', id: 'ky9r22' })}
            </p>
            <ul data-testid="location-details-list">
              {sceneryDetails.slice(0, isMobile ? 2 : length > 2 ? 3 : 2).map(item => (
                <li
                  key={item.type}
                  className="mb-4 text-base font-normal text-primary-base semiHighlight"
                  data-testid={`listing-location-details-${item.type}`}>
                  <FeatureItem
                    bonfireIcon={LOCATION_DETAILS_ICONS[item.type as ELocationDetails]}
                    label={item.label}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className={'mt-6 md:mt-0'}>
            <p className="mb-5 text-lg font-bold leading-500 semiHighlight">
              {intl.formatMessage({ defaultMessage: 'Activities', id: 'UmEsZF' })}
            </p>
            <ul data-testid="location-details-list">
              {activitiesDetails.slice(0, isMobile ? 2 : length > 2 ? 3 : 2).map(item => (
                <li
                  key={item.type}
                  className="mb-4 text-base font-normal text-primary-base semiHighlight"
                  data-testid={`listing-location-details-${item.type}`}>
                  <FeatureItem
                    bonfireIcon={LOCATION_DETAILS_ICONS[item.type as ELocationDetails]}
                    label={item.label}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ClampModal>
    </>
  );
};

interface IModalContentProps {
  sceneryDetails: ILocationDetailsItem[];
  activitiesDetails: ILocationDetailsItem[];
}

export const ModalContent: React.FC<IModalContentProps> = ({
  sceneryDetails,
  activitiesDetails,
}) => {
  const intl = useIntl();
  return (
    <div>
      {!!sceneryDetails.length && (
        <div className={'mt-0'}>
          <Heading level={3} className="pb-2 mb-2 text-xl font-medium semiHighlight">
            {intl.formatMessage({ defaultMessage: 'Scenic features', id: 'TtKpjG' })}
          </Heading>
          <div>
            <ul data-testid="location-details-list-scenery">
              {sceneryDetails.map(item => (
                <li
                  key={item.type}
                  className="py-4 text-base border-b border-gray-200 text-primary-base semiHighlight"
                  data-testid={`listing-location-details-${item.type}`}>
                  <FeatureItem
                    bonfireIcon={LOCATION_DETAILS_ICONS[item.type as ELocationDetails]}
                    label={item.label}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {!!activitiesDetails.length && (
        <div className={'mt-10'}>
          <Heading level={3} className="pb-2 mb-2 text-xl font-medium semiHighlight">
            {intl.formatMessage({ defaultMessage: 'Activities (nearby or onsite)', id: 'SaR+8n' })}
          </Heading>
          <div>
            <ul data-testid="location-details-list-activity">
              {activitiesDetails.map(item => (
                <li
                  key={item.type}
                  className="py-4 text-base border-b border-gray-200 text-primary-base semiHighlight"
                  data-testid={`listing-location-details-${item.type}`}>
                  <FeatureItem
                    bonfireIcon={LOCATION_DETAILS_ICONS[item.type as ELocationDetails]}
                    label={item.label}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationHighlights;
