import { createSelector } from 'reselect';

import { defaultLocale, TCurrency } from '@/config/locales';
import { ELocationDetailCategory } from '@/constants/locationDetails';
import { TRootState } from '@/redux/rootReducer';
import { ICampgroundData, ICampgroundFeatures } from '@/services/types/search/campgrounds/id';

import { ILocationDetailsItem } from '../listing/rentalDetails';

type TListingData = TRootState['campgroundListing']['data'];
type TLoadingListingData = TRootState['campgroundListing']['isLoading'];

type TGetLocationDetails = {
  sceneries: ILocationDetailsItem[];
  activities: ILocationDetailsItem[];
};

export const getCampgroundListingData = createSelector<
  TRootState,
  TListingData,
  ICampgroundData | null
>(
  state => state.campgroundListing.data,
  data => data,
);

export const getCampgroundListingId = createSelector<TRootState, TListingData, number | undefined>(
  state => state.campgroundListing.data,
  data => data?.id,
);

export const isLoadingCampgroundListingData = createSelector<
  TRootState,
  TLoadingListingData,
  boolean
>(
  state => state.campgroundListing.isLoading,
  data => data,
);

export const getAverageCampgroundReviewsRatings = (state: TRootState) =>
  state.campgroundListing.data?.average_reviews;

export const getCampgroundLocationDetails = createSelector<
  TRootState,
  TListingData,
  TGetLocationDetails | null
>(
  state => state.campgroundListing.data,
  data => {
    if (!data) {
      return null;
    }
    const activities = data.campground_catalog?.activity_features
      .filter(activity => {
        return data.features?.[activity.feature as keyof ICampgroundFeatures];
      })
      .map(activity => {
        return {
          type: activity.feature,
          label: activity.name,
          category: ELocationDetailCategory.ACTIVITIES,
        };
      });
    const sceneries = data.campground_catalog.scenery_features
      .filter(sceneryFeature => {
        return data?.features?.[sceneryFeature.feature as keyof ICampgroundFeatures];
      })
      .map(terrainFeature => {
        return {
          type: terrainFeature.feature,
          label: terrainFeature.name,
          category: ELocationDetailCategory.SCENERY,
        };
      });

    return {
      sceneries,
      activities,
    };
  },
);

export const getCampgroundPresentmentCurrency = createSelector<TRootState, TListingData, TCurrency>(
  state => state.campgroundListing.data,
  data => data?.presentment_currency || defaultLocale.base_currency,
);
