import { createSelector } from 'reselect';

import { ELocationDetailCategory } from '@/constants/locationDetails';
import {
  EVehicleDetailCategory,
  EVehicleDetails,
  POSSIBLE_FUEL_TYPES,
} from '@/constants/vehicleDetails';
import { TRootState } from '@/redux/rootReducer';
import { IStayFeatures } from '@/services/types/search/rentals/id';
import { getIntl } from '@/utility/i18n';
import { formatMeasurement } from '@/utility/measurements';
import { mapPluralUnitToSingular } from '@/utility/units';

type TListingData = TRootState['listing']['data'];

export interface IVehicleDetailsItem {
  type?: string;
  label: string;
  value?: number;
  modalLabel?: string;
  description?: string;
  unit?: string;
  category: EVehicleDetailCategory;
}

export interface ILocationDetailsItem {
  type?: string;
  label: string;
  category: ELocationDetailCategory;
}

type TGetRentalDetails = {
  description?: string;
  description_included?: string;
  description_other?: string;
  description_recommendations?: string;
  details: Partial<Record<EVehicleDetails, IVehicleDetailsItem>>;
  vehicleType: string;
};

type TGetLocationDetails = {
  scenery: ILocationDetailsItem[];
  activities: ILocationDetailsItem[];
};

export const parseFuelTypeOfVehicle = (fuelType: string) => {
  if (!POSSIBLE_FUEL_TYPES.includes(fuelType)) return {};

  const intl = getIntl();

  let label = '';

  if (fuelType === 'gas' || fuelType === 'gasoline') {
    label = intl.formatMessage({
      defaultMessage: 'Fuel type: Gas',
      id: '0rQitz',
      description: 'Fuel type',
    });
  }

  if (fuelType === 'diesel') {
    label = intl.formatMessage({
      defaultMessage: 'Fuel type: Diesel',
      id: '9e+rOu',
      description: 'Fuel type',
    });
  }

  if (fuelType === 'electric') {
    label = intl.formatMessage({
      defaultMessage: 'Fuel type: Electric',
      id: '5IBOvX',
      description: 'Fuel type',
    });
  }

  return {
    [EVehicleDetails.FUEL_TYPE]: {
      type: intl.formatMessage({ defaultMessage: 'Fuel type', id: 'VRSLv9' }),
      label,
      category: EVehicleDetailCategory.DRIVABLE,
    },
  };
};

export const getLocationDetails = createSelector<
  TRootState,
  TListingData,
  TGetLocationDetails | null
>(
  state => state.listing.data,
  data => {
    if (!data || data.rental_category !== 'stay') {
      return null;
    }
    const activities = data.stay_catalog?.activity_features
      .filter(activity => {
        return data.stay?.features[activity.feature as keyof IStayFeatures];
      })
      .map(activity => {
        return {
          type: activity.feature,
          label: activity.name,
          category: ELocationDetailCategory.ACTIVITIES,
        };
      });
    const scenery = data.stay_catalog.terrain_features
      .filter(terrainFeature => {
        return data.stay?.features[terrainFeature.feature as keyof IStayFeatures];
      })
      .map(terrainFeature => {
        return {
          type: terrainFeature.feature,
          label: terrainFeature.name,
          category: ELocationDetailCategory.SCENERY,
        };
      });

    return {
      scenery,
      activities,
    };
  },
);

export const getRentalDetails = createSelector<TRootState, TListingData, TGetRentalDetails | null>(
  state => state.listing.data,
  data => {
    if (!data) {
      return null;
    }

    const vehicleType = `${data.vehicle_year} ${data.vehicle_make}  ${data.vehicle_model}`;

    const intl = getIntl();

    const weightUnit = mapPluralUnitToSingular(data.locale?.weight_unit);
    const liquidUnit = mapPluralUnitToSingular(data.locale?.liquid_unit);

    const details = {
      ...(data.sleeps && {
        [EVehicleDetails.SLEEPS]: {
          type: intl.formatMessage({ defaultMessage: 'Beds', id: 'OcfU95' }),
          modalLabel: intl.formatMessage(
            { defaultMessage: 'Beds: {value}', id: 'p4c9YW' },
            { value: data.sleeps },
          ),
          label: intl.formatMessage(
            {
              defaultMessage: 'Sleeps {value}',
              id: 'OFiJi8',
            },
            { value: data.sleeps },
          ),
          value: data.sleeps,
          category: EVehicleDetailCategory.GENERAL,
        },
      }),
      ...(data.seatbelts && {
        [EVehicleDetails.SEATBELTS]: {
          type: intl.formatMessage({ defaultMessage: 'Seatbelts', id: '9dZ5m5' }),
          modalLabel: intl.formatMessage(
            { defaultMessage: 'Seatbelts: {value}', id: 'wfj9tT' },
            { value: data.seatbelts },
          ),
          label: intl.formatMessage(
            {
              defaultMessage: '{value, plural, one {# seatbelt} other {# seatbelts}}',
              id: 'Zy0EvZ',
            },
            { value: data.seatbelts },
          ),
          category: EVehicleDetailCategory.GENERAL,
          value: data.seatbelts,
        },
      }),
      ...(data.features?.water_tank && {
        [EVehicleDetails.WATER_TANK]: {
          type: intl.formatMessage({ defaultMessage: 'Fresh water', id: 'tsLv9M' }),
          modalLabel: intl.formatMessage(
            { defaultMessage: 'Fresh water tank: {value}', id: 'OClVAY' },
            { value: formatMeasurement(data.features?.water_tank, { unit: liquidUnit }) },
          ),
          label: intl.formatMessage(
            { defaultMessage: 'Fresh water: {value}', id: 'Uxe3gV' },
            { value: formatMeasurement(data.features?.water_tank, { unit: liquidUnit }) },
          ),
          description: intl.formatMessage({
            defaultMessage:
              'Stores fresh water to use in the sink, shower, or toilet (if available).',
            id: 'DDjYjH',
          }),
          value: data.features?.water_tank,
          unit: liquidUnit,
          category: EVehicleDetailCategory.DRIVABLE,
        },
      }),
      ...(data.features?.fuel_type && parseFuelTypeOfVehicle(data.features?.fuel_type)),
      ...(data.features?.fuel_tank && {
        [EVehicleDetails.FUEL_TANK]: {
          type: intl.formatMessage({ defaultMessage: 'Fuel capacity', id: 'LXOdiv' }),
          label: intl.formatMessage(
            {
              defaultMessage: 'Fuel capacity: {value}',
              id: 'xfHt6N',
            },
            { value: formatMeasurement(data.features?.fuel_tank, { unit: liquidUnit }) },
          ),
          value: data.features?.fuel_tank,
          unit: liquidUnit,
          category: EVehicleDetailCategory.DRIVABLE,
        },
      }),
      ...(data.features?.transmission && {
        [EVehicleDetails.TRANSMISSION]: {
          type: intl.formatMessage({ defaultMessage: 'Transmission', id: 'HPJbpA' }),
          label:
            data.features.transmission === 'automatic'
              ? intl.formatMessage({
                  defaultMessage: 'Automatic transmission',
                  id: 'g3M+j/',
                  description: 'Automatic',
                })
              : intl.formatMessage({
                  defaultMessage: 'Manual transmission',
                  id: 'aKwYS4',
                  description: 'Manual',
                }),
          modalLabel:
            data.features.transmission === 'automatic'
              ? intl.formatMessage({
                  defaultMessage: 'Transmission: Automatic',
                  id: 'Uw4haO',
                  description: 'Automatic',
                })
              : intl.formatMessage({
                  defaultMessage: 'Transmission: Manual',
                  id: 'dwssbp',
                  description: 'Manual',
                }),
          category: EVehicleDetailCategory.DRIVABLE,
        },
      }),
      ...(data.features?.gray_tank && {
        [EVehicleDetails.GRAY_TANK]: {
          type: intl.formatMessage({ defaultMessage: 'Gray water', id: 'S42sug' }),
          modalLabel: intl.formatMessage(
            { defaultMessage: 'Gray water tank: {value}', id: 'gzw73u' },
            { value: formatMeasurement(data.features?.gray_tank, { unit: liquidUnit }) },
          ),
          label: intl.formatMessage(
            { defaultMessage: 'Gray water: {value}', id: 'DHPIIv' },
            { value: formatMeasurement(data.features?.gray_tank, { unit: liquidUnit }) },
          ),
          description: intl.formatMessage({
            defaultMessage: 'Collects dirty water from the sink or shower (if available).',
            id: '5Fqk3L',
          }),
          value: data.features?.gray_tank,
          unit: liquidUnit,
          category: EVehicleDetailCategory.DRIVABLE,
        },
      }),
      ...(data.features?.sewage_tank && {
        [EVehicleDetails.SEWAGE_TANK]: {
          type: intl.formatMessage({ defaultMessage: 'Black water', id: '/FA2pw' }),
          label: intl.formatMessage(
            { defaultMessage: 'Black water: {value}', id: 'K4yEWH' },
            { value: formatMeasurement(data.features?.sewage_tank, { unit: liquidUnit }) },
          ),
          description: intl.formatMessage({
            defaultMessage: 'Holds waste from the toilet.',
            id: 'MzcPa6',
          }),
          value: data.features?.sewage_tank,
          unit: liquidUnit,
          category: EVehicleDetailCategory.DRIVABLE,
        },
      }),
      ...(data.features?.propane_tank && {
        [EVehicleDetails.PROPANE_TANK]: {
          type: intl.formatMessage({ defaultMessage: 'Propane capacity', id: 'WfKSz6' }),
          label: intl.formatMessage(
            { defaultMessage: 'Propane capacity: {value}', id: 'O9S1rI' },
            { value: formatMeasurement(data.features?.propane_tank, { unit: liquidUnit }) },
          ),
          value: data.features?.propane_tank,
          unit: weightUnit,
          category: EVehicleDetailCategory.DRIVABLE,
        },
      }),
      ...(data.vehicle_gvwr && {
        [EVehicleDetails.GROSS_WEIGHT]: {
          type: intl.formatMessage({ defaultMessage: 'Gross weight', id: 'AehO8y' }),
          label: intl.formatMessage(
            { defaultMessage: 'Gross weight: {value}', id: '3vyXCA' },
            { value: formatMeasurement(data.vehicle_gvwr, { unit: weightUnit }) },
          ),
          value: data.vehicle_gvwr,
          unit: weightUnit,
          category: EVehicleDetailCategory.DRIVABLE,
        },
      }),
      ...(data.features?.hitch_weight && {
        [EVehicleDetails.HITCH_WEIGHt]: {
          type: intl.formatMessage({ defaultMessage: 'Hitch weight', id: 'rKllXP' }),
          label: intl.formatMessage(
            { defaultMessage: 'Hitch weight: {value}', id: 'ef5CT+' },
            { value: formatMeasurement(data.features?.hitch_weight, { unit: weightUnit }) },
          ),
          value: data.features?.hitch_weight,
          unit: weightUnit,
          category: EVehicleDetailCategory.DRIVABLE,
        },
      }),
    };

    return {
      description: data.description,
      description_included: data.description_included,
      description_other: data.description_other,
      description_recommendations: data.description_recommendations,
      details,
      vehicleType,
    };
  },
);
