import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Link from '@/components/switchback/Link/Link';
import { openSessionModal } from '@/utility/session';

import css from './ErrorPage.module.css';

interface IProps {
  statusCode?: number;
  message?: string;
}

const ErrorPage: React.FC<IProps> = ({ statusCode, message }) => {
  const intl = useIntl();
  const handleLogin = () => {
    openSessionModal('login');
  };

  return (
    <div className="max-w-lg px-4 mx-auto my-16" data-testid="error-page">
      <h1 className={`highlight text-6xl mb-4 text-gray-800`} data-status-code={statusCode}>
        <FormattedMessage
          defaultMessage="{statusCode} — Oops!"
          id="7hICk+"
          values={{ statusCode: statusCode }}
        />
      </h1>
      {!!message && <h2 className="text-gray-900 highlight autoType800">{message}</h2>}
      {statusCode === 401 ? (
        <>
          <Button
            onClick={handleLogin}
            className="mt-6"
            variant={EButtonColorVariant.Primary}
            label={intl.formatMessage({ defaultMessage: 'Log in', id: 'odXlk8' })}
          />
          <div className="mt-4 mb-6 font-bold text autoType200">
            <Link href="/users/new?method=recover" target="_blank">
              {intl.formatMessage({
                defaultMessage: 'Forgot your password?',
                id: 'sIjKUz',
                description: 'ErrorPage - Forgotten password link',
              })}
            </Link>
          </div>
        </>
      ) : (
        <h3 className="mb-8 text-lg font-medium">
          {intl.formatMessage({
            defaultMessage: "We can't seem to find the page you're looking for.",
            id: 'sNC64/',
            description: 'ErrorPage - Error message',
          })}
        </h3>
      )}
      <p className="mb-2">
        {intl.formatMessage({
          defaultMessage: "Here's a few helpful links:",
          id: 'mArGfK',
          description: 'ErrorPage - Support title for helpful links',
        })}
      </p>
      <ul className={`${css.links} mb-32`}>
        <li>
          <a href="/">
            {intl.formatMessage({
              defaultMessage: 'Homepage',
              id: 'munfge',
              description: 'ErrorPage - Homepage link text',
            })}
          </a>
        </li>
        <li>
          <a href="/rv-rental">
            {intl.formatMessage({
              defaultMessage: 'Find an RV Rental',
              id: 'Ahxl+s',
              description: 'ErrorPage - Find an RV Rental link text',
            })}
          </a>
        </li>
        <li>
          <a href="/how-it-works">
            {intl.formatMessage({
              defaultMessage: 'How it works',
              id: 'f2H8wx',
              description: 'ErrorPage - How it works link text',
            })}
          </a>
        </li>
        <li>
          <a href="/list-your-rv">
            {intl.formatMessage({
              defaultMessage: 'List your RV',
              id: 'jvaKbB',
              description: 'ErrorPage - List your RV link text',
            })}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ErrorPage;
